import React from "react";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <h2>OPeso Privacy Policy</h2>
        <p>
          CODEBLOCK LENDING INC. (the “Company” or “CODEBLOCK”), the Securities
          and Exchange Commission registered entity which maintains and operates
          the OPeso Application (“OPeso Application”), as well as its assigns,
          affiliates, and related companies in the conduct of its business in
          the Philippines value the privacy of OPeso Users and any person who
          use, access, download, browse, or register with the OPeso Service
          which involves the online lending of short-term loans on the OPeso
          website or the “OPeso Application, or other related digital medium or
          media including OPeso Service’s social media (each and collectively
          the “OPeso Facility”). The existence of this Privacy Policy is part of
          the CODEBLOCK’s commitment to respect and protect the personal data
          and information belonging to Users (as hereinafter defined) of the
          OPeso Facility. We are committed to the protection of any and all
          personal data which may be provided by OPeso Facility Users or
          visitors. This Privacy Policy has been prepared in accordance and in
          compliance with the laws of the Republic of the Philippines, and is
          for the exclusive use of CODEBLOCK. CODEBLOCK is committed to
          complying with the Republic Act 10173 and the Data Privacy Act of
          2012, and its Implementing Rules and Regulations. This Privacy Policy
          sets out the policies of the Company, its assigns, affiliates, and
          related companies in relation to any and all types of personal
          information described in this Privacy Policy, including sensitive
          personal information, as defined under Republic Act No. 10173
          (collectively “Personal Data”, as hereinafter referred to in this
          Privacy Policy), otherwise known as the Data Privacy Act of 2012
          (hereinafter referred to as the “Data Privacy Act”), which may be
          provided by individual users of the OPeso Facility, including
          prospective lenders or borrowers (“you”, the “User” or “Users”), for
          the collection and use by the Company, its assigns, affiliates, and
          related companies, and from which the identity of the individual may
          be apparent or can be reasonably and directly ascertained.
        </p>
        <p></p>
        <p>
          All names and other information, which relate to Users shall be
          handled the manner set out in this Privacy Policy. We only collect
          information where it is necessary for us to do so and we will only
          collect information if it is relevant in our dealings with Users. We
          will only keep User information for as long as we are either required
          by law or as is relevant for the purposes for which the information
          was collected.
        </p>
        <p></p>
        <p>
          You may visit the OPeso Facility and browse without having to provide
          personal details. During visits to the OPeso Facility, you may choose
          to remain anonymous and at no time can we identify Users of the OPeso
          Facility unless you have a OPeso Account and log on with a username
          and password.
        </p>
        <p></p>
        <p>
          The scope of this Privacy Policy is limited to information collected
          or received by us through your use of the OPeso Facility via the OPeso
          website or OPeso Application. We are not responsible for the actions
          of third parties or companies, the contents of their facilities, the
          use of information you provide to them or any products or services
          they may offer. Any link to those sites does not constitute our
          partnership with, sponsorship or endorsement of, or affiliation with,
          those parties or companies or their brands.
        </p>
        <p></p>
        <p>
          By using the OPeso service with the OPeso Facility whether via the
          OPeso website or OPeso Application, you are expressing your agreement
          to this Privacy Policy and the processing of your personal
          information, including your Personal Data, in the manner provided in
          this Privacy Policy. If you do not agree to these terms, please do not
          use and exit the OPeso Facility.
        </p>
        <p></p>
        <p>Personal Data Collected and Handled</p>
        <p></p>
        <p>
          When a User uses the OPeso Facility, including when he/she creates or
          registers an account, uses the OPeso Application, when a transaction
          is created, when a User receives a disbursement, pays using a bank, a
          payment gateway, a payment provider, or through other collections
          partners, and/or when a User avails of any other feature (including
          features from the Member Get Member program) or products and services
          of the OPeso Facility and those provided by its platform lenders or
          service providers, whether available now or as may be developed and
          made available in the future, the following personal information (as
          defined in this Privacy Policy and referred to as “Personal Data”) may
          be collected:
        </p>
        <p></p>
        <p>
          I. Full name, permanent and residential address, contact number/s,
          email address, birth date and/or age, gender, employment information,
          bank account details, credit card and/or financial account
          information, financial history and details of government-issued
          identifications;
        </p>
        <p></p>
        <p>II. SMS Log</p>
        <p>
          We collect your SMS log from your inbox to our server (
          https://api.opeso.ph/api . ) to evaluate the customer's credit, and we
          cannot correctly evaluate whether the customer meets the credit
          conditions required to reach the loan. This enables you to take
          financial facilities from the regulated financial entities and other
          service providers available on the platform.
        </p>
        <p></p>
        <p>
          While using the app, it periodically sends the financial SMS log
          information to our affiliate server and to us.
        </p>
        <p></p>
        <p>
          We collect SMS log information only after obtaining your additional
          explicit consent.
        </p>
        <p></p>
        <p>
          We will delete the SMS log information after we have determined your
          risk profile.
        </p>
        <p></p>
        <p>III. Contact List</p>
        <p>
          We need you to manually select the emergency contacts from your
          contact list including name and phone number, this feature is for when
          we cannot contact you. Only contacts manually selected by users will
          be transmitted to our application (https://api.opeso.ph/api) in an
          encrypted manner, and They will be saved for 1 year from the upload
          date. We will never collect your information without consent. If you
          want to delete your private data, you can send an email to
          service@opeso.ph . Submit a request to customer service and we can
          manually remove it within (3-5 business days).
        </p>
        <p></p>
        <p>IV. Camera/Image</p>
        <p>
          We need you provide images of ID and yourself holding ID from your
          Camera. We will only upload the images you choose to our server (
          https://api.opeso.ph/api . ) through secure encryption.We use them to
          evaluate whether the ID is authentic and valid, and it is indeed you
          who is submitting the financial assessment.
        </p>
        <p></p>
        <p></p>
        <p>
          V. Records of the User’s visits and information submitted when using
          the Site;
        </p>
        <p></p>
        <p>
          VI. social media, e-commerce, transportation, or other online/mobile
          account details.
        </p>
        <p></p>
        <p>
          VII. information about a User, obtained, upon the User’s
          authorization, from any third-party including employers (“Employment
          Information”), and/or other third-party source holding information on
          any financial, transaction, or payment records of the User, and
          historical transactions that the User made or on the User’s behalf;
        </p>
        <p></p>
        <p>
          VIII. We will access your calendar and upload to our
          server(https://api.opeso.ph/api ) only for leveraging calendar
          information to comprehend your financial schedule, enhancing the
          accuracy of our credit models when you apply for a loan. In addition,
          your calendar data will be encrypted over HTTPS and uploaded to our
          server(https://api.opeso.ph/api ). We will strictly protect your data
          and delete data after confirming that it is not used.
        </p>
        <p></p>
        <p>
          The above information shall be obtained (from the User or on the
          User’s behalf from other sources) upon the User’s registration with
          the OPeso Facility or upon the User’s application to avail any OPeso
          products and services and the information shall be collected, used,
          processed, disclosed, retained, stored, and protected by the Company
          in accordance with this Privacy Policy and the OPeso Terms of Service.
        </p>
        <p></p>
        <p>The Personal Data may be collected through the following means:</p>
        <p></p>
        <p>
          a. Personal Data a User provided during and as part of the User’s
          registration, loan application process or when the User uses any
          product or service in the OPeso Facility. In case of data submitted
          for pre-approval, and/or availment of a financial product, the
          information shall be collected by the Company and the applicable
          platform lender.
        </p>
        <p></p>
        <p>
          b. when the User interacts with the OPeso Facility’s customer service
          officers, for example, via telephone calls, letters, face-to-face
          meetings, online chat, social media, messaging apps, emails and other
          communications channels available now or as may be made available in
          the future;
        </p>
        <p></p>
        <p>
          c. when the User chooses to avail of, use or to subscribe to
          third-party services through the
        </p>
        <p></p>
        <p>OPeso Facility;</p>
        <p></p>
        <p>
          d. when the User sign up for OPeso’s services using his/her social
          media account or link the OPeso Account to the User’s social media,
          e-commerce, transportation or other online/mobile account or use
          certain other features of the OPeso Facility;
        </p>
        <p></p>
        <p>
          e. when the User requests to be contacted, be included in an email or
          other mailing list
        </p>
        <p></p>
        <p>
          f. when the User respond to the Company or the OPeso Facility’s
          promotions, initiatives or to any request for additional Personal
          Data;
        </p>
        <p></p>
        <p>
          g. when the User is contacted by, and respond to, the Company or the
          OPeso Facility’s marketing representatives and customer service
          officers;
        </p>
        <p></p>
        <p>
          h. when the Company receives references from business partners and
          third parties, for example, where Users have been referred by them
          (i.e. the User uses a referral code from a company or under the Member
          Get Member program or a coupon code);
        </p>
        <p></p>
        <p>
          i. when the Company or its affiliates seek information from third
          parties about the User in connection with the products and services in
          the OPeso Facility;
        </p>
        <p></p>
        <p>
          j. Personal Data collected from publicly available sources, including
          but not limited to social media, public repositories, websites, and
          similar sources;
        </p>
        <p></p>
        <p>
          k. In accordance with the Authorization and Consent to Collection
          below, Personal Data obtained, on a User’s behalf, from third parties
          with whom the User transacts, from whom the User availed of, is
          currently using, or have obtained a service or a product; and/or
        </p>
        <p></p>
        <p>
          l. Information collected using cookies, web beacons, web crawlers,
          flash cookies, general log information, mobile and browser information
          (in each case in accordance with the User’s permission settings in the
          application and in such devices), User browsing behaviour, User
          searches and transactions and referral information.
        </p>
        <p></p>
        <p>
          Submission of True and Accurate Information – You must submit to us
          information including Personal Data which is true and accurate and not
          misleading and you must keep it up to date and inform of us changes.
          We reserve the right to request for documentation to verify
          information provided by you.
        </p>
        <p></p>
        <p>
          Voluntary Submission of Personal Information / Personal Data- We will
          only be able to collect your Personal Data if you voluntarily submit
          the information to us. If you choose not to submit your Personal Data
          to us or subsequently withdraw your consent to our use of your
          Personal Data, we may not be able to provide you with some or all of
          the services available through the OPeso Application or OPeso
          Facility. You may access and update your Personal Data submitted to us
          at any time.
        </p>
        <p></p>
        <p>
          Linking of Social Media Accounts – If you sign up for OPeso using your
          social media, e- commerce, transportation or other online/mobile
          account (the “Online Account”) or link your OPeso Account to your
          Online Account or use certain other OPeso Facility features, we may
          access information about you (and in linking your OPeso Account to
          your Online Account you consent to us doing so) which you have
          voluntarily provided to the Online Account provider in accordance with
          the provider’s policies and we will manage your Personal Data which we
          have collected in accordance with the OPeso Privacy Policy.
        </p>
        <p></p>
        <p>
          IOS and Android Platform – When you use the OPeso Application on a
          mobile platform, we may collect and record certain information such as
          your unique device ID (persistent/non- persistent), the hardware type,
          media access control (“MAC”), address, international mobile
        </p>
        <p></p>
        <p>
          equipment identity (“IMEI”), the version of your operating system
          (“OS”), your device name, your email address, your profile information
          (if connected to any social media account including Facebook), and
          your location based on your Internet Protocol (“IP”) address. This
          information is useful to us for troubleshooting and helps us to
          understand usage trends. Whenever you use the OPeso Application, we
          may collect data about all of your interactions with the OPeso
          Application or usage of certain features of the OPeso Facility via
          server log files. This data is stored offshore within our cloud
          server.
        </p>
        <p></p>
        <p>
          Log Data- When a User or visitor visits the OPeso Facility, the User’s
          or visitor’s browser may send the Company Personal Data which the
          Company collects for statistical and internal record-keeping purposes.
          The process of collecting this information is called “Log Data”, and
          may include information such as the computer’s IP address, browser
          type, browser version, the visited pages of the OPeso website, the
          time and date of the visit, time spent on the pages or other channels
          of the OPeso Facility including the OPeso Application, and other
          statistics.
        </p>
        <p></p>
        <p>
          Other Collection – We may also acquire information from you through
          (1) your access and participation in the OPeso service and/or the
          OPeso Application or Facility; (2) your participation in surveys
          regarding the OPeso service and/or the OPeso Application or Facility;
          and/or (3) your participation in a promo or contest through the OPeso
          service and/or the OPeso Application or Facility.
        </p>
        <p></p>
        <p>
          Cookies - A cookie is a small text file which asks permission to be
          placed on the visitor’s computer hard drive. A cookie may be
          transferred to your computer or device, to identify a user’s computer
          or device and to “remember" things about your visit through your
          computer or device, such as your preferences or a user name and
          password. Once the visitor agrees, the file is added and the cookie
          helps analyze web traffic or provides internet usage information.
          Information contained in a cookie may be linked to your Personal Data
          for purposes such as improving the quality of the OPeso service,
          tailoring recommendations to your interests, and making the OPeso
          service easier to use. A visitor can instruct his or her browser to
          refuse all cookies or to indicate when a cookie is being sent. You can
          disable cookies at any time, although you may not be able to access or
          use some or all of the features of the OPeso service or the OPeso
          Application. A cookie in no way gives the Company access to the
          visitor’s computer or any information about the visitor, other than
          the data the visitor chooses to share with the Company.
        </p>
        <p></p>
        <p>
          We may feature recommendations served by third parties that deliver
          cookies to your computer or device so the content you access and
          recommendations you see can be tracked. Third-party Recommendations –
          We may feature product and retail partner information within the OPeso
          service or the OPeso Application or Facility. The partner may, upon
          your access of their site or facilities, collect and use information
          about you or your computer or device, such as your OPeso service
          session activity, device identifier, MAC address, IMEI, geo-location
          information and IP address. They may further use this information to
          provide recommendations or product information of interest to you.
          Since the third-party partner companies associate your computer or
          device with a number, they will be able to recognize your computer or
          device each time they send you recommendations or product information.
          These partners may (if you provided your consent and accepted their
          terms or use, privacy
        </p>
        <p></p>
        <p>
          policy or similar document) use information about your visit to the
          OPeso Application or OPeso Facility for the OPeso service and
          third-party sites and applications by your computer or device in order
          to measure your interaction and response to the recommendations and/or
          product information for tracking purposes performance and to provide
          you with better and more appropriate recommendations or product
          information about goods and service of interest to you. In addition,
          you may see the OPeso service or OPeso Application advertised in other
          services or facilities. After clicking on them, you may register to
          become a user of the OPeso service. This Privacy Policy does not apply
          to and we are not responsible for the data collection practices of
          these third-party partners, and we encourage you to check their
          privacy policies to learn more about their use of cookies and other
          technology.
        </p>
        <p></p>
        <p>
          Third-Party Services – Our service may contain third-party tracking
          tools from our service providers. Such third parties may use cookies,
          APIs and SDKs in our services to enable them to collect and analyze
          user information on our behalf. The third parties may have access to
          information such as your device identifier, MAC address, IMEI, locale
          (specific location where a given language is spoken), geo-location
          information, and IP address.
        </p>
        <p></p>
        <p>
          Third-Party Personal Data - If you provide us with any Personal Data
          relating to a third-party individuals (e.g. information of your
          spouse, children, parents, and/or employees, etc.), including those
          submitted for the Member Get Member Program, Related Party Contact and
          Third Party Contact, and other references, by submitting such
          information to us, you represent and warrant that you have obtained
          the consent of the third-party to provide us with their Personal Data
          for the respective purposes in accordance with this Privacy Policy
          (including sharing and disclosure of information to such third
          parties, if necessary) for the use by the Company and the lender such
          that it is considered as legally obtained by the Company and the
          lender, for the purposes and use as enumerated in this Privacy Policy.
          The Company shall confirm with said third-party individuals as soon as
          possible as to whether they consent to be listed as your reference,
          and provide them an option to have their information kept private and
          removed as your reference. In such a scenario, Company may require you
          to provide additional character references.
        </p>
        <p></p>
        <p>
          The Personal Data may be processed on behalf of the Company by
          personal information or personal data processors, in each case mindful
          of Users’ and data subjects’ rights and the Company’s obligations
          under the Data Privacy Act of 2012.
        </p>
        <p></p>
        <p>Authorization and Consent to Collection</p>
        <p></p>
        <p>
          The User expressly and unconditionally consents to the access,
          possession, collection, storage, processing, use, analysis and/or
          distribution, disclosure, display, and delivery of any information
          (including Personal Data) related to the User, and to the transfer of
          such information to any third parties, including, but not limited to,
          the organizer/operator of payment facilities, third-party service
          providers, and third-party sellers, credit bureau (e.g. Credit
          Information Corporation (“CIC”)) and governmental bodies/agencies. The
          User acknowledge and authorise: 1) the regular submission and
          disclosure of the User’s basic credit data (as defined under Republic
          Act No. 9510 and its Implementing Rules and Regulations)
        </p>
        <p></p>
        <p>
          to the CIC as well as any updates or corrections thereof; and 2) the
          sharing of the User’s basic credit data with other lenders authorised
          by the CIC, and credit reporting agencies duly accredited by the CIC.
        </p>
        <p></p>
        <p>
          By downloading and using the OPeso Application and/or the OPeso
          Facility, the User hereby expressly gives consent and authorization
          and is deemed to make the following statement:
        </p>
        <p></p>
        <p>
          “By downloading OPeso, I hereby give consent to the collection and
          processing of my personal information for legitimate business
          purposes, included but not limited to determining my credit score and
          providing a loan. I hereby certify that all the information provided
          by me is true and correct to the best of my knowledge, and that any
          misrepresentations or falsity therein will be considered as an act to
          defraud CB Express Techsystem Corporation (CODEBLOCK), its partners
          and affiliates. I authorize CODEBLOCK to verify and investigate the
          above statements/information as may be required, from the references
          provided and other reasonable sources. For this purpose, I hereby
          waive my rights on the confidentiality of client information and
          expressly consent to the processing of any personal information and
          records relating to me that might be obtained from third parties,
          including government agencies, my employer, credit bureaus, business
          associates and other entities you may deem proper and sufficient in
          the conduct of my business, sensitive or otherwise, in accordance with
          Republic Act No. 10173 for the purpose of determining my eligibility
          for a loan which I am applying for. I further agree that applications
          and all supporting documents and any other information obtained
          relative to this application shall be used by and communicated to
          CODEBLOCK, and shall remain its property whether or not my credit
          score is determined, or the loan is granted. I expressly and
          unconditionally authorize CODEBLOCK to disclose to any bank or
          affiliate and other financial institution any information regarding me
          including the handling thereof to the Bangko Sentral ng Pilipinas, the
          Securities and Exchange Commission, National Privacy Commission,
          Anti-Money Laundering Council, Credit Bureaus and/or any other
          governmental body. In particular, I hereby acknowledge and authorize:
          1) the regular submission and disclosure of my basic credit data (as
          defined under Republic Act No. 9510 and its Implementing Rules and
          Regulations) to the Credit Information Corporation (CIC) as well as
          any updates or corrections thereof; and 2) the sharing of my basic
          credit data with other lenders authorized by the CIC, and credit
          reporting agencies duly accredited by the CIC.”
        </p>
        <p></p>
        <p>
          The User hereby consents to and authorizes CODEBLOCK and the lender
          and/or their third- party partners (such as, but not limited to, the
          CIC, Robinsons Bank Corporation, Robinsons Retail Holdings Inc, Cebu
          Air, Inc., Meralco, PLDT, Smart Telecoms, Globe Telecom, TransUnion,
          CIBI, Compuscan and ShareTreats) for the purpose of providing the
          Services, including conducting user profiling, identify check and
          verification, fraud detection, monitoring and prevention, credit
          investigation, credit scoring and credit collection, to obtain
          pertinent credit, transaction and Personal Data from his/her employer,
          banks, credit card companies, retailers, telecommunications providers,
          IT services, and other relevant institutions in the course of such
          investigation, to verify any and all of the collected information of
          User,
        </p>
        <p></p>
        <p>
          to collect any and all information related thereto, and to process any
          such information, from whatever relevant source. The User further
          acknowledges, understands, accepts and agrees that the User’s Personal
          Data may be shared to a business and/or or marketing partner,
          corporate (i.e. registration using a referral code, link, banner or
          similar referral identification method) or individual (i.e. Member Get
          Member) who have referred the User to register, obtain a loan or
          otherwise obtain or avail of the services from the OPeso Facility
          using a unique identifier (i.e. reference code) for such corporation
          or individual.
        </p>
        <p></p>
        <p>
          The User hereby consents to and authorizes the release by CODEBLOCK
          and its subsidiaries and affiliates, the banks, the credit card
          companies, and the institutions mentioned in the immediately preceding
          paragraph, to release any and all information required by CODEBLOCK,
          the lender, the CODEBLOCK and its subsidiaries and affiliates and/or
          its third- party partners, , such as, but not limited to, the
          following:
        </p>
        <p></p>
        <p>a. Identity & Credit information;</p>
        <p></p>
        <p>b. Voice calling data records;</p>
        <p></p>
        <p>c. SMS sending and receiving records;</p>
        <p></p>
        <p>d. Data usage records;</p>
        <p></p>
        <p>e. Promo subscription and redemption records;</p>
        <p></p>
        <p>f. Customer relationship management records;</p>
        <p></p>
        <p>g. Borrower status; and</p>
        <p></p>
        <p>h. Recovery rate per borrower.</p>
        <p></p>
        <p>i. Financial assets and liabilities</p>
        <p></p>
        <p>j. Regular spending and expenditure</p>
        <p></p>
        <p>
          k. Outstanding loans and/or liabilities at other financial
          institutions
        </p>
        <p></p>
        <p>l. Retail spending record</p>
        <p></p>
        <p>m. information on fraud detection, monitoring and prevention</p>
        <p></p>
        <p>n. app list information</p>
        <p></p>
        <p>o. calendar</p>
        <p></p>
        <p>p. cancel account and delete data</p>
        <p></p>
        <p>
          All the relative data/info reported/stored at https://api.opeso.ph/api
          . The information will only be colledted if you consent to the
          authorization. The data will transfer and upload over a secure
          connection(https).
        </p>
        <p></p>
        <p>Use/Purpose of Personal Data</p>
        <p></p>
        <p>
          The Personal Data shall be processed, collected, used, disclosed,
          stored and retained for the following purposes:
        </p>
        <p></p>
        <p>i. to identify Users;</p>
        <p></p>
        <p>
          ii. to contact Users in relation to their registered account/s or
          requested information;
        </p>
        <p></p>
        <p>
          iii. to process registration of account/s as part of screening of the
          investors, borrowers, and loan applications;
        </p>
        <p></p>
        <p>iv. to maintain internal records;</p>
        <p></p>
        <p>
          v. to market and offering new, related or complementary products and
          services to the User;
        </p>
        <p></p>
        <p>vi. to conduct background check and identity verification;</p>
        <p></p>
        <p>
          vii. to conduct and perform fraud monitoring, detection, analysis and
          prevention;
        </p>
        <p></p>
        <p>
          viii. to prevent, detect and investigate actual or suspected crime and
          analyzing and managing commercial risks;
        </p>
        <p></p>
        <p>ix. to conduct data analytics and tracking;</p>
        <p></p>
        <p>
          x. to conduct market research to enable CB to understand and determine
          customer location, preferences and demographics to develop special
          offers, products or marketing programs in relation to products and
          services, and to improve (i.e. customized offers and products) such
          products and services;
        </p>
        <p></p>
        <p>
          xi. to conduct investigations relating to disputes, payment, suspected
          illegal activities or fraud;
        </p>
        <p></p>
        <p>
          xii. to carry out marketing and promotions initiatives relating to the
          Company, local affiliates of the CODEBLOCK, CODEBLOCK’s partners, and
          CODEBLOCK’s products and services, whether by electronic means, by
          email and post, or by telephone, SMS or social media, or by sending
          newsletters (for example, for the Users to be updated on new products
          or features and to publicize events, contests, demonstrations, loyalty
          programmes, and other activities organized by CODEBLOCK), also by
          using automated means. Such communications will relate to products and
          services of the financial products, services and the like. In
          addition, CODEBLOCK will process and share User data for market
          research, statistical analyses, or other research to improve its
          products, development of new products and customer satisfaction
          analysis;
        </p>
        <p></p>
        <p>
          xiii. to analyse User behavior or for user profiling, (e.g. in
          relation to financing products availed, provided, viewed, and
          searched) in order to improve, and develop better products and
          services in the Facility including to provide better matches among
          lenders and borrowers consistent with their respective priorities;
        </p>
        <p></p>
        <p>
          xiv. to develop, enhance and maintain a risk assessment process and
          model, offline and online;
        </p>
        <p></p>
        <p>
          xv. to develop and generate a credit score, credit model and user
          model;
        </p>
        <p></p>
        <p>
          xvi. to conduct insurance, credit risk analysis, credit investigation,
          credit scoring and credit collection on the User;
        </p>
        <p></p>
        <p>
          xvii. to execute, monitor, manage and analyse the OPeso Facility and
          to develop, enhance, and upgrade the features, functions, products and
          services in the OPeso Facility, including promotions, loyalty and
          reward programs;
        </p>
        <p></p>
        <p>xviii. to respond to your queries and requests;</p>
        <p></p>
        <p>
          xix. to facilitate business asset transactions (which may extend to
          any mergers, acquisitions or asset sales) of the CODEBLOCK;
        </p>
        <p></p>
        <p>
          xx. to facilitate and as may be required as part of any financing or
          capital raising activity (i.e. obtaining loans, credit facility,
          mortgage, assignment, assignment as a form of security or mortgage of
          loans);
        </p>
        <p></p>
        <p>
          xxi. to initiate and maintain correspondence with the User via
          electronic mail (e-mail), SMS (short messaging service), registered
          mail, phone calls or field visits;
        </p>
        <p></p>
        <p>
          xxii. for quality assurance, employee training and performance
          evaluation and identity verification purposes;
        </p>
        <p></p>
        <p>
          xxiii. to provide information to external parties such as government
          agencies, and other institutions as part of scientific research and/or
          study;
        </p>
        <p></p>
        <p>
          xxiv. for any other legal and/or regulatory purposes (including but
          not limited to obtaining legal advice and dispute resolution) and
          legitimate business purposes and to carry out all;
        </p>
        <p></p>
        <p>
          xxv. By giving us your home and/or mobile phone number, we have your
          permission to contact you or the contact number you authorized us.
          This also allow us use text messaging, artificial or prerecorded voice
          messages and automatic dialing technology, for all purposes not
          prohibited by applicable law.Message and data rates may apply. You may
          contact us anytime to change these preferences. We may also send an
          email to any address where we reasonably believe we can contact
          you(include the reference you authorized). Some of the purposes for
          calls and messages include: suspected fraud or identity theft;
          obtaining information; transactions on or servicing of your account;
          and collecting on your account or collecting delinquent account .
          Please make sure you have already told them that they will received a
          sms,a call or email from us asking for your loan information. When
          OPeso communicating with the relevant customer contact provided by
          you, if the contact is explicitly rejected and told to stop
          contacting, OPeso will not call the contact or by other means.Our
          rights under this Section extend to our affiliates, subsidiaries,
          parents, agents, vendors, and anyone so affiliated with the owner of
          any note evidencing a loan you obtain.
        </p>
        <p></p>
        <p>
          xxvi. OPeso collect a list of installed application metadata
          information for each app installed on your device, which includes app
          name, package name, install time, update time, version name, and
          version code. In order to identify and analyze the behaviors and risks
          of users for multiple loans. OPeso will never share data to the third
          party without your permission. The information will only be colledted
          if you consent to the authorization. The data will transfer and upload
          over a secure connection(https).
        </p>
        <p></p>
        <p>
          mandatory administrative, operational and legal duties applicable to
          CODEBLOCK, its subsidiaries and affiliates pursuant to the prevailing
          laws and regulations.
        </p>
        <p></p>
        <p>
          As some practical examples in connection with the above-mentioned uses
          and purposes, we may use information of Users to:
        </p>
        <p></p>
        <p>
          a. allow log-ins and making of purchases by the Users themselves over
          marketplace platforms which are operated under or under affiliation
          with the OPeso service;
        </p>
        <p></p>
        <p>
          b. identify and recommend products and services available through or
          in association with the OPeso Facility and/or the OPeso Application;
        </p>
        <p></p>
        <p>
          c. operate and improve the OPeso Service and/or the OPeso Application;
        </p>
        <p></p>
        <p>
          d. understand User preferences to enhance User experience and
          enjoyment using the OPeso Service and/or the OPeso Application;
        </p>
        <p></p>
        <p>e. respond to User comments and questions or requests;</p>
        <p></p>
        <p>
          f. provide and deliver products and service based on User request or
          demand;
        </p>
        <p></p>
        <p>
          g. send related information, including confirmations, technical
          notices, updates, security alerts, support and administrative messages
          to Users;
        </p>
        <p></p>
        <p>
          h. communicate to Users about promotions, reward, upcoming events, and
          other news about products and services offered by OPeso and selected
          partners of OPeso; and
        </p>
        <p></p>
        <p>
          i. link or combine with other information provided to us from third
          parties, to help understand User preferences with the aim of providing
          Users with better services.
        </p>
        <p></p>
        <p>Sharing of Personal Data</p>
        <p></p>
        <p>
          Your Personal Data may be shared with the CODEBLOCK and its affiliates
          in the conduct of its business for processing as may be required in
          the maintenance and performance of its duties and responsibilities
          under the OPeso Facility, in accordance with applicable laws, rules
          and regulations. The CODEBLOCK or entities which are its subsidiaries
          or within its affiliate network may, as necessary, be engaged by
          CODEBLOCK as subcontractors or data processors in each case with
          respect to the purposes enumerated above.
        </p>
        <p></p>
        <p>
          Personal Data in connection with a lender may be shared with borrowers
          availing the OPeso services. As a borrower, your Personal Data will be
          shared with the lenders in order to allow the lenders to assess your
          credit history and such other information the lender will require to
          confirm your credit history and credit score and to determine or
          assist in determination of decisions as part of screening of
          borrowers, registrations, pre-approval of the lender’s financial
          products and services or loan applications. Your information may also
          be shared with third-party purchasers of any loans including
          Non-Performing Loans. In the event that your loan or other financial
          product is assigned, ceded, taken over, purchased or is otherwise
          transferred to any other party, all your records, transactions, any
          other information (including personal and sensitive personal
          information) about you, as it relates to the issuance, history, basis,
          and status of such loan or financial instrument, shall be shared,
          transferred and delivered to such third party.
        </p>
        <p></p>
        <p>In general, User Personal Data may be disclosed to the following:</p>
        <p></p>
        <p>
          (a) the Company’s affiliates and subsidiaries, agents (including
          collection agencies), and subcontractors;
        </p>
        <p></p>
        <p>
          (b) the government, regulatory agencies, and fraud prevention agencies
          for purposes of identifying, preventing, detecting or tackling fraud,
          money laundering, or other crimes, and/or for other lawful purposes;
        </p>
        <p></p>
        <p>
          (c) other entities as may be required by law or when the Company
          believes that release is appropriate to comply with the law, enforce,
          or to apply the Company’s rights, or to protect the right, property of
          safety of the Company, the OPeso Facility or OPeso Users. This
          includes exchanging information with other companies, organisations or
          regulatory agencies for fraud protection;
        </p>
        <p></p>
        <p>
          (d) as the public interest may warrant, for the purposes identified
          herein in the manner set out in this Privacy Policy;
        </p>
        <p></p>
        <p>
          (e) third-party credit organizations, credit bureaus, credit ratings
          companies, fraud analysis and detection organizations and
          associations, public and private, including the Credit Information
          Corporation, defined above as the “CIC”, and governmental
          bodies/agencies. For avoidance of doubt, the User acknowledge and
          authorise: 1) the regular submission and disclosure of the User’s
          credit data (as defined under Republic Act No. 9510 and its
          Implementing Rules and Regulations) to the CIC as well as any updates
          or corrections thereof; and 2) the sharing of the User’s basic credit
          data with other lenders authorised by the CIC, and credit reporting
          agencies duly accredited by the CIC;
        </p>
        <p></p>
        <p>
          (f) third-party partners such as, but not limited to, CIC, Robinsons
          Bank Corporation, Robinsons Retail Holdings Inc, Cebu Air, Inc.,
          Meralco, PLDT, Smart Telecoms, Globe Telecom, TransUnion, CIBI,
          Compuscan, and ShareTreats for the purpose of conducting identity
          validation and verification, credit analysis and investigation, risk
          analysis and validation, credit scoring and credit collection, which
          shall include the collection of pertinent credit information from a
          User’s employer, banks, credit card companies, and other relevant
          institutions in the course of such investigation, to verify any and
          all of collected information of the User, to collect any and all
          information related thereto, and to process any such information, from
          whatever relevant source;
        </p>
        <p></p>
        <p>
          (g) a business partner, investor, assignee or transferee (actual or
          prospective) to facilitate business asset transactions (which may
          extend to any merger, acquisition or asset sale, or financing)
          involving all or a portion of the CODEBLOCK’s businesses;
        </p>
        <p></p>
        <p>(h) a third-party who will purchase or takeover any loans;</p>
        <p></p>
        <p>
          (i) third parties (e.g. aggregator platforms, payment gateways,
          payment providers, collection agents and banks) who are engaged, used,
          or is otherwise authorized by either the lender or the borrower to
          provide any support or service with respect to any transaction
          concluded by the lender and borrower; and
        </p>
        <p></p>
        <p>
          (j) collection and repossession agents and/or agencies in relation to
          the enforcement of repayment obligations for the loans or other debts.
        </p>
        <p></p>
        <p>
          You as a User hereby acknowledges and agree that CODEBLOCK may share
          any and all information relating to you as a User to third parties for
          purposes provided under this Privacy Policy, for any legitimate
          business purpose including, but not limited to, credit investigation,
        </p>
        <p></p>
        <p>
          credit scoring, credit collection, fraud detection, fraud prevention,
          fraud monitoring, product and systems development, sales and marketing
          activities, communications relating to the development of their
          products and/or services, and the like.
        </p>
        <p></p>
        <p>
          All data processing, subcontracting or data sharing agreements will be
          in accordance with the requirements under the Data Privacy Act of
          2012, or applicable laws and regulations as may be hereafter passed
          and implemented. To the extent that such processing, subcontracting,
          or data sharing requires notice or consent, we will provide or request
          for such notice and/or consent, in each case, prior to any processing
          or sharing of your Personal Data.
        </p>
        <p></p>
        <p>
          Please note that should you agree to sharing of information with other
          third parties for their own marketing purposes, handling of your
          Personal Data shall be subject to their separate privacy policies and
          should you wish to opt-out of marketing or promotional emails from
          these third parties, you should contact these parties directly.
        </p>
        <p></p>
        <p>
          We may release your information including Personal Data as permitted
          by law or when we believe that release is appropriate to comply with
          the law; enforce or apply our rights; or protect the rights, property,
          or safety of us or our users, or others. This includes exchanging
          information with other companies and organizations for fraud
          protection. We may share your information in connection with any
          merger, sale of our assets, or a financing or acquisition of all or a
          portion of our business to another company. We may share aggregate or
          anonymous information about you with advertisers, publishers, business
          partners, sponsors, and other third parties.
        </p>
        <p></p>
        <p>
          On occasion, we may share to or obtain user-level data collected about
          you from third parties (including affiliates), for purposes beyond the
          specific examples noted in this Privacy Policy. User-level data is
          information that is associated with your specific device and is not
          aggregated with other data. For example, we may share to or obtain
          data from third-party data that reveals that you have viewed certain
          sites or used certain apps or features, but you will not be personally
          identified with such information. Rather, the user-level data will be
          associated with an anonymous identifier (such as user ‘X’).
        </p>
        <p></p>
        <p>Storage of Personal Data</p>
        <p></p>
        <p>
          The data including Personal Data that we collect from you may be
          transferred to, and stored at, a destination outside the Philippines.
          It may also be processed by staff operating outside the Philippines
          who work for us or for one of our suppliers. These staff members may
          be engaged in the fulfillment of your requests. By submitting your
          Personal Data, you agree to this transfer, storing or processing of
          data. We will take all steps reasonably necessary to ensure that your
          data is treated securely and in accordance with this Privacy Policy.
          We may also disclose your data to anyone we consider necessary in
          order to provide you with services in connection with an account.
        </p>
        <p></p>
        <p>
          Where we have given you (or where you have chosen) a password or PIN
          that enables you to
        </p>
        <p></p>
        <p>
          access certain parts of the OPeso Facility, you are responsible for
          keeping this password confidential. We ask you not to share a password
          with anyone. Unfortunately, the transmission of information via the
          internet is not completely secure. Although we will do our best to
          protect your Personal Data, we cannot guarantee the security of your
          data transmitted through the OPeso Facility, and any transmission is
          at your own risk. Once we have received your information, we will use
          strict procedures and security features to try to prevent unauthorized
          access.
        </p>
        <p></p>
        <p>Retention of your Personal Data</p>
        <p></p>
        <p>
          Your Personal Data will be retained by the Company for the duration of
          your activities and transactions in connection with the products and
          services availed of in this OPeso Facility and/or for such period of
          time required for legal and regulatory and/or other legitimate
          business purposes, and will be disposed of in a secure manner that
          would prevent further processing, unauthorized access or disclosure to
          any other party or the public or prejudice your interest, provided
          that the Company may retain copies of your Personal Data in the
          Company’s archives for the purpose of determining its continuing
          obligations or pursuant to its bona fide record retention or data
          back-up policies, access to which shall be restricted on a
          need-to-know basis, as may be required under applicable laws and
          regulations.
        </p>
        <p></p>
        <p>
          The Personal Data may likewise be retained for the purpose of fraud
          prevention, detection, and monitoring in accordance with the Company’s
          fraud detection and prevention policies, and any requirement imposed
          by any credit agency, bureau or agency in order to prevent fraud,
          identify theft, anti-money laundering, terrorist financing and other
          similar causes which are consistent with public policy and the public
          good.
        </p>
        <p></p>
        <p>Security of your Personal Data</p>
        <p></p>
        <p>
          The Company values Personal Data collected, used, retained and stored
          through the OPeso Facility. The Company has adopted technical,
          organizational and physical measures to protect your Personal Data,
        </p>
        <p></p>
        <p>
          We take reasonable measures to protect your information from
          unauthorized access or against loss, misuse, or alteration by third
          parties.
        </p>
        <p></p>
        <p>
          Although we make good faith efforts to store the information collected
          on the OPeso Facility and/or the OPeso Application in a secure
          operating environment that is not available to the public, we cannot
          guarantee the absolute security of that information during its
          transmission or its storage on our system. Further, while we attempt
          to ensure the integrity and security of our network and systems, we
          cannot guarantee that our security measures will prevent third- party
          “hackers" from illegal obtaining access to this information. We do not
          warrant or represent that your information will be protected against,
          loss, misuse, or alteration by third parties.
        </p>
        <p></p>
        <p>Access and Correction of Personal Data</p>
        <p></p>
        <p>
          If you wish to exercise your right to access, correction,
          cancellation, portability and objection rights as described below, or
          for complaints and other inquiries, please send a registered letter
          with return receipt to 7th floor, BA Lepanto Building, 8747 Paseo de
          Roxas, Makati City 1227, Philippines or email service@opeso.ph to the
          attention of Data Privacy Officer Eugene Chua.
        </p>
        <p></p>
        <p>
          • to receive details of the source of the data, the purposes and
          methods of the processing, the logic applied to the processing, or if
          the latter is carried out with the help of electronic means, of the
          identity of data controller and data processors, the entities or
          categories of entity to which the data may be communicated and which
          may become aware of the data;
        </p>
        <p></p>
        <p>
          • to update, correct, supplement, or delete the data, to block or
          render anonymous data that have been processed unlawfully, including
          data whose retention is unnecessary for the purpose for which such
          data have been collected or subsequently processed; to have a
          certification that the operations requested have been completed, as
          also related to their contents, to the entities to which the data were
          communicated, unless this requirement proves impossible or involves a
          manifestly disproportionate effort compared with the right that is to
          be protected; to receive Personal Data concerning you and copy or
          transmit it to another data controller (right to data portability);
          and
        </p>
        <p></p>
        <p>
          • to object, on legitimate grounds, to the processing of data, despite
          the data being relevant to the purposes for which they were collected
          or to object, also partially (e.g. only in relation to certain
          communication means), to the data being processed in order to send
          advertising materials or for direct sales, or market research, or
          commercial information, profiling purposes, or to receive
          communications by traditional means only.
        </p>
        <p></p>
        <p>
          Please note that if your Personal Data has been provided to us by a
          third-party (e.g. a referrer), you should contact that organization or
          individual to make such queries, complaints, access and correction
          requests.
        </p>
        <p></p>
        <p>Control over your Personal Data</p>
        <p></p>
        <p>
          Users retain control over their Personal Data, and may amend their
          Personal Data registered over the OPeso Facility database.
        </p>
        <p></p>
        <p>
          The User shall be responsible for maintaining the confidentiality of
          his or her own OPeso Account and password, as well as any and all
          applications submitted, obligations agreed to or entered into and all
          other activities occuring under the said account. The User agrees to
          immediately notify the Company of any unauthorized use or disclosure
          of the User’s account or password, any unauthorized activities under
          the User’s OPeso Account or any other breach of security.
        </p>
        <p></p>
        <p>
          The Company will not be liable for any loss that the User may incur as
          a result of a third- party’s use of the User’s OPeso Account or
          password, either with or without the User’s knowledge, without fault
          or negligence on the part of the Company.
        </p>
        <p></p>
        <p>
          The User may be held liable for any damage or loss incurred by the
          Company in connection with or arising from a third-party’s use of the
          User’s OPeso Account or password, without fault or negligence on the
          part of the Company.
        </p>
        <p></p>
        <p>Third-Party Service</p>
        <p></p>
        <p>
          Our service may contain third-party tracking tools from our service
          providers. Such third parties may use cookies, APIs and SDKs in our
          services to enable them to collect and analyse user information on our
          behalf. The third parties may have access to information such as your
          device identifier, MAC address, IMEI, locale (specific information
          where a given language is spoken), geo-location information, and IP
          address.
        </p>
        <p></p>
        <p>Opting Out of Marketing Communication</p>
        <p></p>
        <p>
          You may opt-out of receiving promotional emails or other notification
          channels from us by following the instructions in those emails or
          notification channels. If you opt out, we may still send you
          non-promotional emails or notifications, such as emails or
          notifications about your accounts or pertaining to our ongoing
          business including where due notices are required under law or
          regulation, for the purposes of User protection, or in the
          circumstances the Company deems that it is necessary to keep Users
          updated via written notice. Please note that should you agree to
          sharing of information with other third parties for their own
          marketing purposes, handling of your Personal Data shall be subject to
          their separate privacy policies and should you wish to opt-out of
          marketing through promotional emails or other notification channels
          from these third parties, you should contact these parties directly.
        </p>
        <p></p>
        <p>User Obligations</p>
        <p></p>
        <p>
          You agree to inform us promptly, and in any event, within 14 calendar
          days, in writing if there are any changes to your information supplied
          to us from time to time, and to respond promptly to any request from
          us.
        </p>
        <p></p>
        <p>Where:</p>
        <p></p>
        <p>
          • you fail to provide promptly any user information or Personal Data
          that we reasonably requested;
        </p>
        <p></p>
        <p>
          • you withhold or withdraw any consents that we may need to process,
          transfer or disclose Personal Data for the purposes of used disclosed
          above in this Privacy Policy (except for purposes connected with
          marketing or promoting products and services to you); and/or
        </p>
        <p></p>
        <p>
          • we have, or a member of the CODEBLOCK has, suspicions regarding
          financial crime or an associated risk.
        </p>
        <p></p>
        <p>We may:</p>
        <p></p>
        <p>
          • be unable to provide new, or continue to provide all or part of the,
          OPeso service to you
        </p>
        <p></p>
        <p>and reserve the right to terminate our relationship with you;</p>
        <p></p>
        <p>
          • take actions necessary for us or a member of the CODEBLOCK to meet
          the compliance or regulatory obligations; and/or
        </p>
        <p></p>
        <p>
          • suspend, transfer or close your account(s) where permitted under
          local laws.
        </p>
        <p></p>
        <p>Data Privacy Information Rights</p>
        <p></p>
        <p>Users have the following rights regarding Personal Data:</p>
        <p></p>
        <p>
          1.1 Right to Request Special Privacy Protections. You have the right
          to request restrictions on certain uses and disclosures of your
          Personal Data by a written request specifying what information you
          want to limit, and what limitations on our use or disclosure of that
          information you wish to have imposed. If you tell us not to disclose
          information, we will abide by your request, unless we must disclose
          the information for treatment or legal reasons. We reserve the right
          to accept or reject any other request, and will notify you of our
          decision.
        </p>
        <p></p>
        <p>
          1.2 Right to Inspect and Copy. You have the right to inspect and copy
          your Personal Data, with limited exceptions. To access your Personal
          Data, you must submit a written request detailing what information you
          want access to, whether you want to inspect it or get a copy of it,
          and if you want a copy, your preferred form and format. We will
          provide copies in your requested form and format if it is readily
          producible, or we will provide you with an alternative format you find
          acceptable, or if we can’t agree and we maintain the record in an
          electronic format, your choice of a readable electronic or hard copy
          format. We will also send a copy to any other person you designate in
          writing. We will charge a reasonable fee which covers our costs for
          labour, supplies, postage, and if requested and agreed to in advance,
          the cost of preparing an explanation or summary. We may deny your
          request under limited circumstances. If we deny your request to access
          your personal records or the personal records of an individual you are
          representing because we believe allowing access would be reasonably
          likely to cause substantial harm to the customer, you will have a
          right to appeal our decision.
        </p>
        <p></p>
        <p>
          1.3 Right to Request Confidential Communications. You have the right
          to request that you receive your Personal Data in a specific way or at
          a specific location. You may ask that we send information to a
          particular e-mail account or to your work address. We will comply with
          all reasonable requests submitted in writing which specify how or
          where you wish to receive these communications.
        </p>
        <p></p>
        <p>
          1.4 Right to Amend or Supplement. You have a right to request that we
          amend your Personal Data that you believe is incorrect or incomplete.
          You must make a request to amend in writing, and include the reasons
          you believe the information is inaccurate or incomplete. We may deny
          your request if we do not have the information, if we did not create
          the information, if you would not be permitted to inspect or copy the
          information at issue, or if the information is accurate and complete
          as is. All information related to any request to amend will be
          maintained and disclosed in conjunction with any subsequent disclosure
          of the disputed
        </p>
        <p></p>
        <p>information.</p>
        <p></p>
        <p>
          1.5 Right to Revoke Authorization. Your consent is necessary before
          the Company can lawfully collect and process your Personal Data. You
          may revoke (take back) your consent at any time. For the avoidance of
          doubt, this does not invalidate any previous processing that the
          Company has performed in accordance with consent originally given.
        </p>
        <p></p>
        <p>
          1.6 Right to Erasure or Blocking. You have the right to suspend,
          withdraw or order the blocking, removal or destruction of your
          Personal Data. You can exercise this right upon discovery and
          substantial proof of any of the following:
        </p>
        <p></p>
        <p>
          1.7 We provide you with the right to cancel your account after paying
          off loan orders.If you want to stop using our service and delete your
          account, please enter www.opeso.ph/delete to submit a request of
          deletion, we will ask you to provide your account information and
          related proof to verify and validate your identity; after
          verification, we will delete your account and related data.{" "}
        </p>
        <p></p>
        <p>
          i. Your Personal Data is incomplete, outdated, false, or unlawfully
          obtained.
        </p>
        <p></p>
        <p>ii. It is being used for purposes you did not authorize.</p>
        <p></p>
        <p>
          iii. The data is no longer necessary for the purposes for which they
          were collected.
        </p>
        <p></p>
        <p>
          iv. You decided to withdraw consent, or you object to the processing
          and there is no overriding legal ground for processing.
        </p>
        <p></p>
        <p>
          v. The data concerns information prejudicial to the customer — unless
          justified by freedom of speech, of expression, or of the press; or
          otherwise authorized by law.
        </p>
        <p></p>
        <p>vi. The processing is unlawful.</p>
        <p></p>
        <p>
          vii. The Company, or its Personal Data processor/s, violated your
          rights as a customer.
        </p>
        <p></p>
        <p>
          1.7 Right to Be Notified of a Breach. You have the right to receive
          notifications of breaches of your Personal Data, as may be required by
          law. Aside from protecting you against unfair means of Personal Data
          collection, this right also requires the Company to notify you in a
          timely manner if your Personal Data has been compromised. If you have
          provided us with a current e-mail address, we may use e-mail to
          communicate information related to the breach. 1.8 Right to a Paper or
          Electronic Copy of this Notice. You have a right to be notified of our
          legal duties and privacy practices with respect to your Personal Data,
          including a right to a paper copy of this Privacy Notice, even if you
          have previously requested its receipt by e-mail.
        </p>
        <p></p>
        <p>
          1.8 Right to Damages. You have the right to be indemnified for any
          damages sustained due to inaccurate, incomplete, outdated, false,
          unlawfully obtained, or unauthorized use of your Personal Data. When
          there is a perceived violation of your rights, you may file a
          complaint with the National Privacy Commission.
        </p>
        <p></p>
        <p>Disclosure Statement for Users and Reference Persons</p>
        <p></p>
        <p>Statement For Users</p>
        <p></p>
        <p>
          2.1 Payment. We use and disclose Personal Data about you to other
          providers under or in partnership with the OPeso service, with an
          affiliate company or parent company and other vendors to obtain
          payment for the services we have provided.
        </p>
        <p></p>
        <p>
          2.2 Payment Reminders. We may use and disclose your Personal Data to
          contact and remind you about due payments.
        </p>
        <p></p>
        <p>
          2.3 Research. You agree that your Personal Data may be used and
          disclosed for research purposes; provided, that access is granted
          strictly on a need to know basis to protect customer confidentiality
          involving Personal Data. Separately, your personal records may be used
          for data analytics to create customer demographics and trends;
          however, these reports are generated in such a way that customer
          identities are anonymized.
        </p>
        <p></p>
        <p>
          2.4 Marketing. We may use your contact details to communicate with you
          and give you information about products, promotions or services
          related to your loan applications or other services provided by OPeso,
          with an affiliate company or parent company and other business vendors
          that may be of interest to you. We may also contact you to ensure
          customer satisfaction regarding services provided, including
          identification of your concerns so that addressing of issues are
          assured.
        </p>
        <p></p>
        <p>
          2.5 Required by Law. As required by law, we will use and disclose your
          Personal Data, but we will limit our use or disclosure to the relevant
          requirements of the law. When the law requires us to report abuse or
          respond to judicial or administrative proceedings, or to law
          enforcement officials, we will further comply with the requirement set
          forth below concerning those activities. We may disclose your Personal
          Data to an oversight facility for activities authorized by law. These
          oversight activities include, for example, audits, investigations,
          inspections, and licensure.
        </p>
        <p></p>
        <p>
          2.6 Law Enforcement. We may, and are sometimes required by law to,
          disclose your Personal Data to a law enforcement official for purposes
          such as identifying or locating a suspect, fugitive, material witness
          or missing person, complying with a court order, warrant, subpoena and
          other law enforcement purposes.
        </p>
        <p></p>
        <p>
          2.7 Other Uses and Disclosures: If we wish to use or disclose your
          Personal Data for a purpose that is not discussed in this Privacy
          Notice, your written consent will be requested separately. Statement
          For Reference Persons, Related Party Contacts and Third-Party
          Contacts:
        </p>
        <p></p>
        <p>
          3. Additional Information. If you are identified by a OPeso User as a
          Reference Person, Related Party Contact or Third Party Contact, we may
          contact you to ask for additional information about our User who named
          you as his/her reference person, to verify the information provided in
          a User’s application, and/or other information such as the User’s new
          address and/or new contact number, in the event that we have an
          important information for the User (for example, if a borrower
          defaults on his/her payment obligation to us) and we are unable to
          reach the User or borrower using the contact information that he/she
          provided to us.
        </p>
        <p></p>
        <p>
          Statement For Users in relation to verification with Related Party
          Contacts and Third Party Contacts
        </p>
        <p></p>
        <p>
          4.1 If you provide information of nominated Related Party Contacts
          and/or Third Party
        </p>
        <p></p>
        <p>
          Contact, you understand, accept, and expressly and unconditionally
          consent to the sharing and disclosure of any information related to
          the you (including your Personal Data and/or sensitive personal
          information), to your nominated Related Party Contacts and/or Third
          Party Contacts, as such Related Party Contact and Third Party Contact
          may be changed from time to time, for the purpose of:
        </p>
        <p></p>
        <p>
          (a) verifying any or all information about you (whether submitted by
          you or obtained from other sources) prior to, during and after the
          application, approval, and termination of the loan agreement;
        </p>
        <p></p>
        <p>
          (b) to inquire about, request for, ascertain or determine the
          whereabouts, contact information, financial status, and other
          information related to you (including making such inquiries as part of
          the loan collection process);
        </p>
        <p></p>
        <p>
          (c) for fraud, theft, or other investigation related to any suspected
          or actual criminal action or activity, in each case for the purpose of
          the review, processing, approval, administration, collection,
          servicing, sale, transfer, amendment, or such other matters in
          relation to an existing or future loan, which shall be implemented, in
          accordance with any mandatory applicable law or regulation as the same
          may be issued from time to time.
        </p>
        <p></p>
        <p>
          4.2 In some instances, we also share Personal Data with our suppliers,
          including CODEBLOCK entities and other business partners who provide
          services to us, such as IT and technology service providers, marketing
          providers, communication services and printing providers, debt
          collection, tracing, debt recovery, fraud prevention, and credit
          reference agencies, and others. When we do so we take steps to ensure
          they meet our data security standards, so that your Personal Data
          remains secure.
        </p>
        <p></p>
        <p>
          4.3 If you no longer wish us to share your Personal Data with such
          person(s) or wish to change your preferences in this respect, please
          inform us (dpo@opeso.ph) in writing. When we provide your Personal
          Data to any of the above recipients, that recipient may process your
          Personal Data on our behalf or for its/his/her own purposes. If the
          recipient processes the Personal Data for its own purposes, it/he/she
          will be responsible for due compliance with any applicable law with
          regard to your Personal Data.
        </p>
        <p></p>
        <p>Changes on our Privacy Policy</p>
        <p></p>
        <p>
          This Privacy Policy may be updated from time to time and shall
          continue to remain in effect unless otherwise amended. If this Privacy
          Policy is changed, those changes will be posted on the OPeso Facility
          and/or the OPeso Application. The change will be effective when
          posted. Users availing OPeso services and/or visitors to the OPeso
          Facility are advised to check the OPeso website and/or OPeso
          Application on a regular basis to remain aware of the policies and
          practices of how information is collected and used.
        </p>
        <p></p>
        <p>
          If significant changes to this Privacy Policy will affect certain
          Personal Data, the Company shall notify its Users through the email
          address provided or by posting a public notice on the OPeso
          Application and/or OPeso Facility.
        </p>
        <p></p>
        <p>
          The foregoing consents and authorizations shall continue for the
          duration of, and shall survive the termination of any loan
          agreement(s) signed by the borrower, or any other transactions,
          dealings, arrangements and accounts which borrower may have with, or
          avail from, the Company and the lender through OPeso service and/or
          the OPeso Facility.
        </p>
        <p></p>
        <p>
          This version of the Privacy Policy is updated as at 22 August 2019
        </p>
        <p></p>
        <p>Contact Us</p>
        <p></p>
        <p>
          For any questions, concerns or clarifications about this Privacy
          Policy, the Company may be contacted through the customer support
          system at dpo@opeso.ph.
        </p>
        <p></p>
        <p>
          I HAVE READ THESE TERMS OF SERVICE AND AGREE TO ALL OF THE PROVISIONS
          CONTAINED ABOVE AND ANY REVISION THE SAME HEREAFTER. BY CLICKING THE
          “ACCEPTANCE” BUTTON BELOW, and/or “SIGN UP”, “CONNECT”, “REGISTER” OR
          “SUBMIT” BUTTON OF ANY SOCIAL MEDIA OR PUBLIC PLATFORMS LINKED TO THE
          OPeso SERVICE, I UNDERSTAND THAT I AM CREATING A DIGITAL SIGNATURE,
          WHICH I INTEND TO HAVE THE SAME FORCE AND EFFECT AS IF I HAD SIGNED MY
          NAME MANUALLY.
        </p>
        <p></p>
        <p>ACCEPTANCE</p>
        <p></p>
        <p>
          I confirm having read and understood and agree to the foregoing
          Privacy Policy. DIGITALLY SIGNED
        </p>
      </div>
    );
  }
}
export default PrivacyAgreement;
