import React from "react";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class ServiceAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <h2>OPESO TERMS OF SERVICE</h2>
        <p>
          By using, accessing, downloading, browsing or registering to the OPeso
          service on the OPeso website, the OPeso mobile application (the “OPeso
          Application”), or other related digital medium or media including
          social media (each and collectively the OPeso “Facility”), you signify
          that you have read, understood, and accepted these Terms of Service
          and agree to be bound by them, including all related rules and
          regulations for the use and availment of any service, product or
          information of the OPeso Facility, as the same is currently available
          or as may be made available in the future. You likewise agree to be
          bound by any and all laws, rules, regulations and governmental
          issuances applicable to the OPeso Facility or the Company (as
          hereinafter defined below), now existing or which may hereinafter be
          issued, as well as such other terms and conditions governing the use
          of other facilities, benefits, products or services which we may make
          available to you in connection with the OPeso service, such as but not
          limited to Member Get Member Programs, or other similar programs as
          may be offered in the future. You may be required to register an
          account (“OPeso Account”) to use the OPeso service. If you access the
          OPeso service via any OPeso Facility whether via the OPeso
          Application, upon your access of the OPeso Facility to use the OPeso
          service, these Terms of Service shall be a binding agreement between
          you and Oriente Express Techsystem Corporation (“OETC” or the
          “Company”). If you do not agree, do not fully understand or have
          reservations with respect to any provision of these Terms of Service,
          please exit and stop your use of this OPeso Facility.
        </p>
        <p></p>
        <p>Eligibility</p>
        <p></p>
        <p>
          The OPeso Facility is intended solely for the use of an individual at
          least eighteen (18) years of age, in good credit standing, and has the
          legal capacity to enter into binding agreements, specifically the
          availment of products and/or services offered in the OPeso Facility.
        </p>
        <p></p>
        <p>
          By creating and maintaining a OPeso Account, including to use any
          product, service, facilities, or benefits in the OPeso Facility, you
          represent and warrant that you possess the foregoing eligibility
          requirements. You likewise expressly authorize, consent and permit the
          Company to use all necessary means to verify your identity, conduct
          fraud check, fraud validation, background checks, credit and
          background checks, based on the information you have provided, or by
          obtaining data from other sources including from any third-party
          providers of information. The use of the OPeso Facility is also
          governed by the OPeso Privacy Policy, incorporated herein by
          reference.
        </p>
        <p></p>
        <p>Registration and Application</p>
        <p></p>
        <p>
          In creating your OPeso Account or applying to become a borrower or
          lender, you agree to provide the true, current, complete and accurate
          information about yourself in the registration or application form
          which is necessary for purposes of availing services under the OPeso
          Facility. The Company and its partner lenders reserve the right to
          conduct a background verification of your identity, including to
          conduct a credit and fraud investigation prior to, during and for the
          duration of your registration, application, use of the OPeso Facility,
          any loan, or any other service or feature. If any information you
          provide is untrue, inaccurate, not current, or incomplete, we reserve
          the right to cancel your registration, suspend your OPeso Account,
          reject any application you have submitted, and restrict your future
          use of the OPeso Facility and availment of products and services
          available over the OPeso Facility. The Company also reserves the right
          to reject any registration, deny access to the OPeso Facility and/or
          decline any loan application or investment in violation of these Terms
          of Service.
        </p>
        <p></p>
        <p>
          Prior to registration and use of the OPeso Facility, it is important
          for you to know that the services can only be provided if you agree to
          disclose relevant identification, employment and financial information
          which you will be required to provide and may also be obtained by the
          Company, on your behalf, from all relevant sources in accordance with
          your instruction and authorization (the “Authorization”).
        </p>
        <p></p>
        <p>
          By accepting and clicking on the “Agree” button below, you hereby
          acknowledge that you have read and accepted the Privacy Policy and
          agree that the collection, storage, processing (including for purposes
          of development of a credit score and user profiling) and disclosure of
          the information you have provided (including regarding nominated
          Related Party Contacts and/or Third Party Contacts or other reference
          persons) or will provide to third parties, such as but not limited to,
          a lender, borrower, third-party purchaser of a loan including
          delinquent loan, third-party data processors, credit bureau (including
          the Credit Information Corporation (CIC) and other submitting entities
          to whom the CIC may, as permitted by law share such data), credit
          organization, as is necessary and required for the access and use of
          the OPeso Facility and its services.
        </p>
        <p></p>
        <p>
          Please read and confirm that you agree to the Authorization and
          Special Power of Attorney. If you do not agree or have reservations
          with respect to any provision of the Authorization and Special Power
          of Attorney, as to its scope, coverage or purpose, please exit the
          OPeso Facility and discontinue using the OPeso Service.
        </p>
        <p></p>
        <p>General Privacy</p>
        <p></p>
        <p>
          In accessing and using the OPeso Facility, you agree that the
          following personal information (hereinafter referred to as “Personal
          Data”) may be collected, used and processed in accordance with the
          OPeso Privacy Policy:-
        </p>
        <p></p>
        <p>
          Full name, permanent and residential address, contact number/s, email
          address, birth date and/or age, gender, employment information, bank
          account details, credit card and/or financial account information,
          financial history and details of government-issued ID;
        </p>
        <p></p>
        <p>
          social media, e-commerce, transportation and other online/mobile
          account details and other information from which your identity may not
          be apparent or which may not reasonably and directly identify you,
          such as, but not limited to, records of your visits and information
          you submit when using the OPeso Facility;
        </p>
        <p></p>
        <p>
          information from third parties including information obtained, upon
          your authorization, from any third party including your employers
          (“Employment Information), and other third-party source holding
          information on any financial, transaction, or payment records of the
          User, and historical transactions;
        </p>
        <p></p>
        <p>
          traffic, usage, device, and other application information generated or
          found in your device and from your use of the OPeso Facility; and
        </p>
        <p></p>
        <p>
          Anonymous, technical, network, and interaction data generated or
          available in the device or any other access medium used to access or
          make use of the OPeso Facility.
        </p>
        <p></p>
        <p>
          The above information shall be obtained (from you or on your behalf
          from other sources) upon your registration in the OPeso Facility or
          upon your application to avail any of the OPeso Facility’s products
          and services shall be collected, used, processed, disclosed, retained,
          stored, and protected by the Company in accordance with the OPeso
          Privacy Policy and these OPeso Terms of Service:
        </p>
        <p></p>
        <p>
          A general summary of how Personal Data is handled is set out as below.
          Please refer to the OPeso Privacy Policy for more detailed information
          regarding all collection, use, processing, sharing, storage and
          transfer of user data through the OPeso Facility including Personal
          Data.
        </p>
        <p></p>
        <p>
          The Personal Data may be collected through the following means:
          cookies, flash cookies, general log in information, information
          obtained during OPeso Account registration, information from
          transactions transacted through the OPeso Account, information
          obtained from your employer, your device (subject to your permission
          settings), and referral information from third-party websites,
          sources, applications and offline sources.
        </p>
        <p></p>
        <p>The Personal Data may be disclosed to the following:</p>
        <p></p>
        <p>
          (a) the Company’s affiliates and subsidiaries, agents (including
          collection agencies), and subcontractors, which are necessary for the
          conduct of the Company’s business and on a need-to-know basis;
        </p>
        <p></p>
        <p>
          (b) The lenders with respect to Personal Data of borrowers, and the
          borrowers with respect to Personal Data of the lenders;
        </p>
        <p></p>
        <p>
          (c) third-party providers, including those who require the information
          to facilitate the loan, including but not limited to the payment,
          remittance, or processing of loan to the borrower, payments to the
          lender, transfer of the loan to a third-party (i.e. financing company,
          new lender, monetary authority);
        </p>
        <p></p>
        <p>
          (d) the government, regulatory agencies, and fraud prevention agencies
          for the purposes of identifying, preventing, detecting, monitoring or
          tackling of suspected, or actual fraud, money laundering, or other
          crimes, and for other lawful purposes;
        </p>
        <p></p>
        <p>(e) marketing with third-party partners; and</p>
        <p></p>
        <p>
          (f) other entities as may be required by law or as public interest may
          warrant.
        </p>
        <p></p>
        <p>
          In the event that your Personal Data is shared to any third-party for
          the purposes not described above, such sharing shall be subject to a
          data sharing agreement or an outsourcing agreement which shall
          require, among others, that such third-party is bound to exercise the
          same diligence in the protection of such Personal Data. You shall be
          notified by the Company of such sharing prior to any processing of
          your Personal Data, at the email address you provided upon
          registration. In the event that you wish to withhold your consent to
          any such processing or sharing, such request shall be deemed as a
          request for the deletion of your OPeso Account and the consequent
          termination of your transactions in accordance with the terms and
          conditions of the agreements you accepted and executed.
        </p>
        <p></p>
        <p>
          The Company may use your Personal Data or other internet usage data as
          the Company may require in connection with the conduct of the
          Company’s business, such as, but not limited to:
        </p>
        <p></p>
        <p>(i) identify you as user of the OPeso Facility;</p>
        <p></p>
        <p>
          (ii) contact you in relation to your registered OPeso Account/s or
          requested information;
        </p>
        <p></p>
        <p>
          (iii) processing registration of your OPeso Account/s as part of
          screening of the investors, borrowers, and loan applications;
        </p>
        <p></p>
        <p>(iv) user profiling;</p>
        <p></p>
        <p>(v) generation of a credit score;</p>
        <p></p>
        <p>(vi) fraud monitoring, prevention and detection; and</p>
        <p></p>
        <p>(vii) to maintain internal records.</p>
        <p></p>
        <p>
          The Company shall retain your Personal Data for the duration of your
          activities and transactions in connection with the products and
          services availed; for the establishment, and for its due diligence,
          fraud prevention and detection program, background and credit checks,
          offer of new products and services, credit scoring and assessment,
          exercise or defense of a legal claim; for legitimate business
          purposes; or in instances required by law. Your Personal Data shall
          then be disposed in a secure manner that would prevent further
          processing, unauthorized access, or disclosure to any other party or
          the public, or prejudice your interest.
        </p>
        <p></p>
        <p>User Content</p>
        <p></p>
        <p>
          By submitting content to the OPeso Facility, you expressly agree to
          the following:
        </p>
        <p></p>
        <p>
          You retain all ownership rights to the content you have uploaded on
          the OPeso Facility.
        </p>
        <p></p>
        <p>
          You hereby grant the Company a non-exclusive, transferable,
          sub-licensable, royalty-free, world license to use, reproduce,
          distribute, prepare derivative works of, display, and perform any
          information or content that you provide in connection with your use of
          the OPeso Facility and its services, subject to the privacy provisions
          in these OPeso Terms of Service and the OPeso Privacy Policy. You
          grant the Company the right to review, delete, edit, reformat,
          excerpt, or translate any of your information or content.
        </p>
        <p></p>
        <p>
          You are solely responsible for the content and information you make
          available through or in connection with our products and services. The
          Company will not be liable for any use or misuse of your Personal Data
          by others.
        </p>
        <p></p>
        <p>
          All the information and content posted on the OPeso Facility or
          privately transmitted through the OPeso Facility or via other means in
          connection with the OPeso Facility’s services is the sole
          responsibility of the person from whom that content originated. The
          Company will not be responsible for any errors in or omission of any
          information or content posted by a user.
        </p>
        <p></p>
        <p>
          The Company may access and use the information recorded by credit
          reference and fraud prevention agencies for purposes of assessing
          lending risks and identifying, preventing, detecting or tackling
          fraud, money laundering and other crimes.
        </p>
        <p></p>
        <p>
          You hereby expressly undertake the sole responsibility and hold the
          Company and the Oriente Group free of liability to you or any
          third-party, for any content that you create, upload, transmit, or
          display while using the OPeso service via the OPeso Facility. You also
          undertake not to share, create, upload, transmit or furnish using the
          OPeso Facility any material, information or content which is or may be
          covered by copyright, patent, trade secret, trademark, tradename,
          service mark or any property rights, including privacy and/or
          publicity rights, unless you have the necessary licences, rights,
          consents and permissions to use and to authorise the Company and OPeso
          to use any and/or all the content that you create, upload, transmit or
          furnish using the OPeso Facility.
        </p>
        <p></p>
        <p>
          You undertake not to use fictitious name or make any concealment of
          true name for the purpose of concealing crime, evading the execution
          of a judgement or causing damage.
        </p>
        <p></p>
        <p>
          You agree and hereby undertake sole responsibility for the
          consequences of your actions and interactions using the OPeso
          services, and hereby stipulate admission of liability to the Company
          and the Oriente Group for whatever loss or damage the Company or the
          Oriente Group may suffer as a consequence of your use or misuse of the
          OPeso services or access to the OPeso Facility.
        </p>
        <p></p>
        <p>Third-Party Content & Services</p>
        <p></p>
        <p>
          The OPeso Facility may contain content provided by third-party
          services and resources. You acknowledge and agree that we are not
          responsible or liable for the availability or accuracy,
          appropriateness, completeness or non-infringement of third-party
          services, or the contents, products or services available on or
          through such third-party services. Unless specified by the Company in
          respect of its OPeso service via the OPeso Facility and/or otherwise
          notified to you, the availability of such third-party services does
          not imply any endorsement by us of such third-party services of the
          content, products or services available therefrom. The Company and the
          OPeso service makes no representation or warranties whatsoever about
          any service, content and/or other resources provided by the
          third-party. You may not copy, remove, alter, or obscure any
          copyright, trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the products or services in respect of
          any content and/or other resources provided by the third-party.
        </p>
        <p></p>
        <p>
          We assume no responsibility for the conduct of any third-party
          merchant submitting any content on their products and services or from
          their resources, and we assume no responsibility for monitoring the
          OPeso service for inappropriate contents or conduct. We do not, and
          cannot, pre-screen or monitor all user and partner content. Your use
          of the OPeso service and availment of the products and services made
          available via the OPeso Facility shall be at your own risk. By using
          the OPeso service, you may be exposed to content that may be not in
          line with your expectations. You bear all risks associated with your
          reliance on any available content in connection with the OPeso
          Service.
        </p>
        <p></p>
        <p>No Warranty; Errors</p>
        <p></p>
        <p>
          The products and services available on the OPeso Facility, including
          products and services provided by third parties through the OPeso
          Facility are provided on “as is”, “as available” basis and without any
          representation or warranty of any kind. To the fullest extent
          permissible under applicable laws, the Company disclaims all such
          warranties, express or implied, including, but not limited to,
          warranties of merchantability, fitness for a particular purpose,
          non-infringement, accuracy, freedom from errors, suitability of
          content, availability, payment or realization of transactions. The
          Company, the OPeso Facility and the OPeso service make no warranty in
          whatsoever manner.
        </p>
        <p></p>
        <p>
          The Company does not warrant the content, quality, accuracy, adequacy
          or completeness of the information provided over the OPeso Facility
          and expressly disclaims liability for any errors or omissions in such
          information, in particular over any content and/or other resources
          provided by the third-party in respect of products or services offered
          through or via linkage to the OPeso Facility. The Company does not
          guarantee and promise any specific results from use of products and
          services made available over the OPeso Facility or that the availment
          of any part or all of the OPeso service will be uninterrupted and
          error-free.
        </p>
        <p></p>
        <p>
          The Company shall not be responsible for information users or merchant
          partners may post, disclose, publish or share on the OPeso Facility or
          any offensive, inappropriate, obscene, unlawful or otherwise
          objectionable content uploaded, disclosed, published, or shared by
          other users or merchant partners. The Company is not responsible for
          the conduct, whether online or offline, of any user or merchant
          partner of the OPeso Facility or its products or services made
          available through the OPeso Facility.
        </p>
        <p></p>
        <p>
          The Company is not responsible for the accuracy of the information,
          content, products or services offered by, or the information practices
          adopted by sites linked to or from the OPeso Facility. Since
          third-party websites, platforms or facilities may have different
          privacy policies and/or security standards governing their sites, we
          advise you to carefully review the privacy policies and terms and
          conditions of these websites, platforms or facilities prior to
          providing any Personal Data. If you provide any Personal Data to any
          third-party websites, platforms or facilities, they may process your
          Personal Data on for its/his/her own purposes, and it/he/she will be
          responsible for its compliance with any applicable law with regard to
          your Personal Data and appropriate disclosures to you. You are advised
          to contact these third parties directly should you have any concerns
          or queries arising therefrom.
        </p>
        <p></p>
        <p>Partner Merchant or Third-Party Products and Services</p>
        <p></p>
        <p>
          For purchases of third-party products and services available over the
          OPeso Facility, diligence is expected of the User to only transact
          with reputable merchants. The Company shall not be held liable and
          accountable for any unauthorized transaction. Once the transaction has
          been authorized, you bind yourself with the merchant’s terms of sale
          such as but not limited to on how you will receive the goods and/or
          services that you purchased (including the validity and expiry of
          voucher code etc).The Company and the Oriente Group is not liable and
          accountable for any undelivered goods and services, defects, damages
          and after-sales services of such goods and /or services of third
          parties. You shall be fully accountable for payment transactions
          especially in dealing with merchants that may have limited refund
          policy for invalid disputes.
        </p>
        <p></p>
        <p>Responsibility for OPeso Account</p>
        <p></p>
        <p>
          You are solely responsible for maintaining the confidentiality of your
          username, password, account and any other login or authentication and
          validation information you created or provided to the OPeso Facility,
          including any mobile one-time password (i.e. one-time-password sent to
          your registered phone number that is valid for only one login session
          or transaction and in a certain period of time, to authenticate and
          identify you.) (“Unique User Credentials”). By creating, registering
          and maintaining your account, you agree to honour all activities
          performed and obligations contracted using your OPeso Account.
        </p>
        <p></p>
        <p>
          You agree and confirm that any use of your Unique User Credentials
          shall at all times be presumed to be access by you and, if by a
          third-party, with your consent and authority. Any and all activities
          or transactions using your Unique User Credentials shall be valid and
          binding transactions created, committed and performed by you.
        </p>
        <p></p>
        <p>
          If there is an unauthorized use of your account or a breach of its
          security, you hereby undertake to notify the Company of the relevant
          circumstances thereof immediately.
        </p>
        <p></p>
        <p>Liability for Account Misuse</p>
        <p></p>
        <p>
          The Company will not be liable for any loss that you may incur as a
          result of someone else using any of your account or Unique User
          Credentials, either with or without your knowledge. You could be held
          liable for losses incurred by the Company due to a third-party using
          your account or Unique User Credentials.
        </p>
        <p></p>
        <p>Account Security</p>
        <p></p>
        <p>
          While the Company has implemented adequate safeguards as required
          under the Data Privacy Act of 2012, the Company does not give any
          guarantee or warranty with respect to the same. You hereby acknowledge
          that you provide your Personal Data at your own risk.
        </p>
        <p></p>
        <p>
          You shall not share your OPeso Account or login information, nor let
          anyone else access your OPeso Account or do anything else that might
          jeopardize the security of your OPeso Account.
        </p>
        <p></p>
        <p>
          In the event you become aware of or reasonably suspect any breach of
          security, including without limitation any loss, theft, or
          unauthorized disclosure of the login Information, you must immediately
          notify us and modify your login Information. You are solely
          responsible for maintaining the confidentiality of the login
          Information, and you will be responsible for all uses of the OPeso
          Facility and the login Information, including making of purchases and
          loan applications, whether or not authorized by you. You are
          responsible for anything that happens to your OPeso Account.
        </p>
        <p></p>
        <p>
          We reserve the right to remove or reclaim any usernames at any time
          and for any reason, including but not limited to claims by a
          third-party that a user name violates the third-party’s rights
        </p>
        <p></p>
        <p>Restrictions on Use</p>
        <p></p>
        <p>
          You agree to abide by all applicable terms and conditions, laws and
          regulations in your use of the OPeso Facility, and availment of
          products and services available over the OPeso Facility. In addition,
          you agree that you will not do any of the following:
        </p>
        <p></p>
        <p>
          register for more than one account at any given time using one
          international mobile equipment identity (IMEI), or register for an
          account on behalf of an individual other than yourself or on behalf of
          any group or entity;
        </p>
        <p></p>
        <p>
          post or otherwise make available content, or take any action on the
          Site, that may constitute libel or slander or that infringes or
          violates someone else's rights or is protected by any copyright or
          trademark, or otherwise violates the law;
        </p>
        <p></p>
        <p>
          post or or otherwise make available content that in the Company’s
          judgment is objectionable, such as content that is harmful,
          threatening, inflammatory, obscene, fraudulent, invasive of privacy or
          publicity rights, hateful, or otherwise objectionable, or which
          restricts or inhibits any other person from using or enjoying the
          OPeso Facility, or which may expose the Company or users of the OPeso
          Facility to any harm or liability of any type;
        </p>
        <p></p>
        <p>
          post or otherwise make available any unsolicited or unauthorized
          advertising, solicitations, promotional materials, or any other form
          of solicitation;
        </p>
        <p></p>
        <p>
          use the information or content on the OPeso Facility to send unwanted
          messages to any other user;
        </p>
        <p></p>
        <p>
          impersonate any person or entity, or falsely state or otherwise
          misrepresent yourself, your age or your affiliation with any person or
          entity;
        </p>
        <p></p>
        <p>
          post or otherwise make publicly available on the OPeso Facility any
          personal or financial information of any third-party;
        </p>
        <p></p>
        <p>
          solicit passwords or personally identify information for commercial or
          unlawful purposes;
        </p>
        <p></p>
        <p>
          use the OPeso Facility or our products and services in any manner that
          could damage, disable, overburden or impair the OPeso Facility;
        </p>
        <p></p>
        <p>
          harvest or collect email addresses or other contact information of
          other users from the OPeso Facility by electronic or other means,
          including the use of automated scripts; and/or
        </p>
        <p></p>
        <p>
          post or otherwise make available any material that contains software
          viruses or any other computer code, files or programs designed to
          interrupt, destroy or limit the functionality of any computer software
          or hardware or telecommunications equipment.
        </p>
        <p></p>
        <p>Reserved Rights</p>
        <p></p>
        <p>
          We reserve, at our sole discretion but without obligation, the right
          to:
        </p>
        <p></p>
        <p>
          deny access to the OPeso Facility and / or discontinue the OPeso
          service or any component thereof to anyone at any time, temporarily or
          permanently, without giving any reason and/or prior notice to you. You
          hereby irrevocably agree, affirm and warrant to hold the Company and
          the Oriente Group free from any liability, both under equity and the
          law, arising or that may arise out of any such denial of access to the
          OPeso Facility or the discontinuance of the OPeso service;
        </p>
        <p></p>
        <p>
          collect, screen, review, flag, filter, modify, block, refuse or remove
          any and/or all information provided by any user, explicitly or
          implicitly to and through the OPeso Facility, including but not
          limited to hardware information, IP address, browser-type related
          information, cookies and the like. You hereby irrevocably agree,
          affirm and warrant to hold the Company and the Oriente Group free from
          any liability, both under equity and the law, arising or that may
          arise out of any such collection, screening, review, flagging,
          filtering, modification, blocking, refusal or removal of any and/or
          all information provided by any user to and through the OPeso
          Facility;
        </p>
        <p></p>
        <p>
          enhance, improve, develop and introduce for the OPeso service new
          features and functionalities via the OPeso Facility at any time and
          without prior notice. You hereby understand, agree, and affirm that
          any such enhancement, improvement, development, new feature and/or new
          functionality at the OPeso Facility shall form part of the OPeso
          service as defined herein and thus shall likewise be covered by these
          OPeso Terms of Service and its subsequent revisions or amendments, as
          applicable;
        </p>
        <p></p>
        <p>
          verify, check, cross-refer, validate, and ascertain the veracity and
          truthfulness of all information supplied by you by acquiring,
          accessing, retrieving, or otherwise acquiring similar or additional
          information supplied by you to other third-party service providers,
          including, but not limited to telecommunications providers, etc. You
          hereby expressly, unequivocally, and voluntarily allow the Company to
          request for and secure such information, and expressly, unequivocally,
          and voluntarily instruct such third-party providers to: (a) receive
          and process information requests; (b) favourably act at all times on
          any such request by producing the information requested; and (c) when
          requested, provide the latter with certified digital or printed copies
          of the said information;
        </p>
        <p></p>
        <p>
          send you or cause to send you service updates and/or messages,
          including SMS, notifications, email and/or any data message
          transmission, informing you of enhancements, improvements,
          developments, features, functionalities, products, promotions, offers,
          advertisement and/or any other information relative to the OPeso
          service. The Company and the OPeso service make no warranty of any
          kind, express or implied, for such service updates and/or messages,
          but you hereby agree to receive such service updates and/or messages
          and hold the Company and the Oriente Group free from any liability
          and/or claims for indemnification or damages that may arise therefrom;
        </p>
        <p></p>
        <p>
          set limitations to and charge fees and applicable taxes for the use of
          the OPeso service or the OPeso Facility, at any time and without prior
          notice (other than required regulatory disclosures which will be
          disclosed to you). You further understand and agree that the Company
          and the lender reserves the right, at its sole discretion and under no
          obligation, to change the applicable fees, taxes and charges levied
          for the use of the OPeso service, at any time and with prior written
          notice to you before the effective date of change in fees / charges
          /penalties;
        </p>
        <p></p>
        <p>
          to limit the provision, availability, quantity and quality of any
          feature, product or service to any person or to anyone within the same
          geographic area, demographic profile, or any other market, commercial,
          and/or trading segments. You likewise understand and agree that the
          Company reserves the right, at its sole discretion but without
          obligation, to administer and operate any and/or all of the OPeso
          service from any or various locations outside the Republic of the
          Philippines. You hereby understand, agree, and undertake sole
          responsibility for your continued access to the OPeso Facility or use
          of the OPeso service, as well as the results or consequences of such
          access and use, including the responsibility for compliance with
          applicable local laws and the sole liability for non-compliance or
          breach thereof.
        </p>
        <p></p>
        <p>Contracts and Authorizations</p>
        <p></p>
        <p>
          Your use and availment of the products and services on the OPeso
          Facility may be conditioned on your agreement to certain contracts and
          authorizations. By signifying your consent and permissions therein,
          you and agree to be bound terms and conditions thereof and such
          contracts and authorizations shall be deemed validly and voluntarily
          executed by you. Upon request, the Company may furnish you electronic
          copies of such documents.
        </p>
        <p></p>
        <p>Termination</p>
        <p></p>
        <p>
          The Company may terminate or suspend your access to or ability to use
          the OPeso Facility immediately, without prior notice or liability, for
          any reason or no reason, including breach of these OPeso Terms of
          Service.
        </p>
        <p></p>
        <p>
          Termination of your access to and use of the OPeso Facility shall not
          relieve you of any obligations arising or accruing prior to
          termination or limit any liability that you otherwise may have to the
          Company or any third-party.
        </p>
        <p></p>
        <p>Limitation of Liability</p>
        <p></p>
        <p>
          Neither Company, nor the lender nor the Oriente Group shall not be
          liable for any loss, cost, compensation, damage or liability to you or
          third-party arising from, directly or indirectly, or as a result of
          any or all of the following:
        </p>
        <p></p>
        <p>
          a. refusal of any bank, financial institution, ATM or merchant
          establishment and the like to allow, accept or honour the OPeso
          service;
        </p>
        <p></p>
        <p>
          b. the OPeso service is honoured by any bank, financial institution,
          ATM or merchant establishment; however, the transaction is not
          authorized, for any reason whatsoever;
        </p>
        <p></p>
        <p>
          c. the User is unable to perform or complete any transaction through
          the use of the OPeso Application or any part of the OPeso Facility due
          to service/ system/ line unavailability;
        </p>
        <p></p>
        <p>
          d. any delay, interruption or termination of a transaction whether
          caused by administrative error, technical, mechanical, electrical or
          electronic fault or difficulty or any other reason or circumstance
          beyond the Company and the OPeso service’s control (including but not
          limited to acts of God, strike, labour disputes, fire, disturbance,
          action of government, atmospheric conditions, interference or damage
          by third parties or any change in legislation);
        </p>
        <p></p>
        <p>
          e. unauthorized use of any user OPeso Account or any loss, costs,
          damages or payable to any third-party by the OPeso service User; or
        </p>
        <p></p>
        <p>
          f. any misrepresentation or fraud by or misconduct of any third-party.
        </p>
        <p></p>
        <p>
          To the fullest extent permitted by applicable law, in no event shall
          the Company, its directors, officers, representatives, agents, or
          assigns be liable for any direct, special, indirect or consequential
          damages, or any other damages of any kind, including but not limited
          to loss of use, loss of profits or loss of data, whether in an action
          in contract, tort (including but not limited to negligence) or
          otherwise, arising out of or in any way connected with the use of or
          inability to use the OPeso Facility, including, without limitation,
          any damages caused by or resulting from reliance by user on any
          information obtained from the OPeso Facility, or that result from
          mistakes, omissions, interruptions, deletion of files or email,
          errors, defects, viruses, delays in operation or transmission or any
          failure of performance. You expressly agree that your use of the OPeso
          Facility is at your sole risk.
        </p>
        <p></p>
        <p>
          The Company, its directors, officers, representatives, agents, or
          assigns, shall not be liable to you for loss or damage of any kind
          which you may suffer as a result of being a member of the OPeso
          Facility, except where such loss or damage arises from our breach of
          these OPeso Terms of Service or was caused by gross negligence, wilful
          default or fraud by the Company or employees. The Company shall also
          not be responsible for any breach of these OPeso Terms of Service
          arising from circumstances outside our reasonable control.
        </p>
        <p></p>
        <p>Liability for Breach</p>
        <p></p>
        <p>
          You shall be liable for any loss or damage suffered by the Company
          and/or its users as a result of:
        </p>
        <p></p>
        <p>
          your breach of these OPeso Terms of Service or any agreement you have
          entered into pursuant to the OPeso Facility’s services;
        </p>
        <p></p>
        <p>your fraudulent use of the OPeso Facility; and</p>
        <p></p>
        <p>your provision of inaccurate, false or fraudulent data.</p>
        <p></p>
        <p>Intellectual Property Rights</p>
        <p></p>
        <p>
          The design, trademarks, service marks, and logos of the OPeso Facility
          (“OPeso Marks”), are owned by or licensed to the Company, similarly
          the design, trademarks, service marks, and logos of third parties may
          be owned by or licensed to the third parties, and subject to copyright
          and other intellectual property rights under the laws of the Republic
          of the Philippines, foreign laws and/or international conventions. You
          may not use, copy, or distribute of any of the OPeso Marks or design,
          trademarks, service marks, and/or logos of third parties found on the
          OPeso Facility unless otherwise expressly permitted.
        </p>
        <p></p>
        <p>Changes to OPeso Terms of Service</p>
        <p></p>
        <p>
          The Company reserves the right to make changes to these OPeso Terms of
          Service (“Updated Terms”) at any time. Unless the changes in the Terms
          of Service are for legal or administrative reasons, the Company will
          provide reasonable advance notice before the Updated Terms become
          effective by posting the Updated Terms on the OPeso Facility.
        </p>
        <p></p>
        <p>
          Your use of the OPeso Facility after the effective date of the Updated
          Terms constitutes your agreement to the Updated Terms. You should
          review these Terms of Service and any Updated Terms before continuing
          to use the OPeso Facility.
        </p>
        <p></p>
        <p>Miscellaneous</p>
        <p></p>
        <p>
          In the event that any provision of these OPeso Terms of Service is
          deemed by any competent authority to be unenforceable or invalid, the
          relevant provision shall be modified to allow it to be enforced in
          line with the intention of the original text to the fullest extent
          permitted by applicable law. The validity and enforceability of the
          remaining provisions of these OPeso Terms of Service shall not be
          affected.
        </p>
        <p></p>
        <p>
          You agree that all documents or notices may be delivered to you
          electronically, through your e-mail address provided upon
          registration. Accordingly, you recognize and acknowledge that it is
          your sole responsibility to update the Company with your current
          e-mail address and the Company shall not be liable for any claim of
          loss or damage for failure to receive notices.
        </p>
        <p></p>
        <p>
          Subject to applicable law, all disclaimers, indemnities and exclusions
          in these OPeso Terms of Service shall survive termination of any loan
          agreement(s).
        </p>
        <p></p>
        <p>
          No single or partial exercise, or failure or delay in exercising any
          right, power or remedy by us shall constitute a waiver by us of, or
          impair or preclude any further exercise of, that or any right, power
          or remedy arising under these OPeso Terms of Service or otherwise.
        </p>
        <p></p>
        <p>
          Unless expressly agreed in writing otherwise, these OPeso Terms of
          Service set out the entire agreement between you and us with respect
          to your use of the OPeso Facility and supersedes any and all
          representations, communications and prior agreements (written or oral)
          made by you or us.
        </p>
        <p></p>
        <p>
          These OPeso Terms of Service are governed by and construed in
          accordance with laws of the Republic of the Philippines. All matters,
          claims or disputes arising out of or in connection with these OPeso
          Terms of Service, shall be submit to the exclusive jurisdiction of the
          courts of Pasig City.
        </p>
        <p></p>
        <p>
          This version of the Terms of Service is last updated on 22 August 2019
        </p>
        <p></p>
        <p>Lender Authorization</p>
        <p></p>
        <p>
          By accepting these Terms of Service, you understand that as a lender
          you assume the risk of non-payment. To mitigate this risk, the Company
          may, to the extent possible, assign any Non-Performing Loan* (“NPL”)
          to a third-party, in each case endeavouring that you shall receive the
          unpaid principal, interest, and penalties which you are entitled to
          receive under the terms and conditions of the loan. In the event that
          the loan or financial product is assigned, ceded, taken over,
          purchased or is otherwise transferred to any other party, all loan and
          financial product records, transactions, and any other information
          (including personal and sensitive personal information), as it relates
          to the issuance, history, basis, and status of such loan or financial
          instrument, shall be shared, transferred and delivered to such
          third-party.
        </p>
        <p></p>
        <p>
          In accordance with the above, you hereby authorize and designate the
          Company, as your authorized representative and attorney-in-fact to
          assign, sell, or otherwise dispose/assign the NPL to a third-party,
          upon prior notice. The Company will inform you, via email of such
          assignment and shall credit the unpaid principal, interest, and
          penalties to your account on record.
        </p>
        <p></p>
        <p>USER ACCEPTANCE</p>
        <p></p>
        <p>
          I confirm having read and understood and agree to the foregoing OPeso
          Terms of Service.
        </p>
        <p></p>
        <p>
          By accepting these OPeso Terms of Service and the OPeso Privacy Policy
          I signify your express consent in accordance with Republic Act No.
          10173, otherwise referred to as the Data Privacy Act of 2012 and its
          Implementing Rules and Regulations as well as other applicable
          confidentiality and data privacy laws of the Republic of the
          Philippines. I agree to hold the Company, its officers, directors and
          stockholders, free and harmless from any and all liabilities, damages,
          actions, claims, and suits in connection with the implementation or
          processing of Personal Data in relation to my consent or authorization
          and my use of the OPeso Facility under these Terms of Service.
        </p>
        <p></p>
        <p>DIGITALLY SIGNED</p>
        <p></p>
        <p>
          I HAVE READ THESE TERMS OF SERVICE AND AGREE TO ALL OF THE PROVISIONS
          CONTAINED ABOVE AND ANY REVISION THE SAME HEREAFTER. BY CLICKING THE
          “SIGN UP” OR “CONNECT” OR “REGISTER” OR “SUBMIT” BUTTON OF ANY SOCIAL
          MEDIA OR PUBLIC PLATFORMS LINKED TO THE OPESO SERVICE, I UNDERSTAND
          THAT I AM CREATING A DIGITAL SIGNATURE, WHICH I INTEND TO HAVE THE
          SAME FORCE AND EFFECT AS IF I HAD SIGNED MY NAME MANUALLY.
        </p>
        <p></p>
        <p>
          *A Non-Performing Loan is defined as any loan that is in default, as
          provided under the applicable loan document.
        </p>
      </div>
    );
  }
}
export default ServiceAgreement;
